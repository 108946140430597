.header {

}

.top {
    display: flex;
    flex-direction: row;
    justify-items: right;
    justify-content: space-between;
    align-items: center;
}

.top .topButtons {
    display: flex;
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: stretch;
    padding: 5px 0 0 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-top-width: 1px;
}

.logo {
    width: 240px;
    height: 120px;
}
