.header {
    grid-column-start: 2;
}

.home {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 20px 0 0 0;
    width: auto;
}

.section {
    padding: 20px 0 0 0;
}
